import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
  Tooltip,
} from "@mui/material";
import { QueuePlayNext, DeleteForever, Label, Help } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import MyTransferList from "../../components/transfer-list/transferlist";
import MyCheckList from "../../components/checklist/checklist";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";

import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import SimpleCheckBox from "../../components/checkbox/check";
import Autocomplete from "@mui/material/Autocomplete";
import { getAllWithoutIncludes } from '../../services/unit.service'
import { getAll as getStates } from '../../services/states.service.'
import { saveAs } from "file-saver";
import { Input } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { getOrg } from "../../services/organization.service";
import * as Service from "../../services/user.service";
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'
import NumberFormat from "react-number-format"
import SimpleHelp from "../../components/help"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { PapiroConsole } from "../../utils/papiroConsole";
import DialogAddMultiple from "../../components/dialog-add-multiple";

const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}

class UserRegistration extends Component {
  constructor(props) {
    super(props);

    const { userSession } = this.props;

    const intl = this.props.intl;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }
    PapiroConsole.log("USER SESSION AQUI")
    PapiroConsole.log(userSession)
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    this.startPageItems = [
      { id: 'calleds', name: intl.formatMessage({ id: "called.header" }) },
      { id: 'dashboard', name: intl.formatMessage({ id: "dashboard.operation" }) },
      { id: 'portal', name: intl.formatMessage({ id: "portal" }) },
      { id: 'knowledgebases-client', name: intl.formatMessage({ id: "knowledgebase.panel" }) }
    ]

    this.genderList = [
      { id: 1, name: intl.formatMessage({ id: "gender.male" }) },
      { id: 2, name: intl.formatMessage({ id: "gender.female" }) }
    ]

    this.stateList = [
      { id: 1, name: 'Acre' },
      { id: 2, name: 'Alagoas' },
      { id: 3, name: 'Amapá' },
      { id: 4, name: 'Amazonas' },
      { id: 5, name: 'Bahia' },
      { id: 6, name: 'Ceará' },
      { id: 7, name: 'Espírito Santo' },
      { id: 8, name: 'Goiás' },
      { id: 9, name: 'Maranhão' },
      { id: 10, name: 'Mato Grosso' },
      { id: 11, name: 'Mato Grosso do Sul' },
      { id: 12, name: 'Minas Gerais' },
      { id: 13, name: 'Pará' },
      { id: 14, name: 'Paraíba' },
      { id: 15, name: 'Paraná' },
      { id: 16, name: 'Pernambuco' },
      { id: 17, name: 'Piauí' },
      { id: 18, name: 'Rio de Janeiro' },
      { id: 19, name: 'Rio Grande do Norte' },
      { id: 20, name: 'Rio Grande do Sul' },
      { id: 21, name: 'Rondônia' },
      { id: 22, name: 'Roraima' },
      { id: 23, name: 'Santa Catarina' },
      { id: 24, name: 'Sao Paulo' },
      { id: 25, name: 'Sergipe' },
      { id: 26, name: 'Tocantins' },
      { id: 27, name: 'Distrito Federal' }
    ]

    this.initialState = {
      startPage: '',
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      Organizations: 0,
      Location: 0,
      Groups: 0,
      OrganizationText: "",
      GroupText: "",
      LocationText: "",
      name: "",
      email: "",
      document: "",
      gender: null,
      state: 0,
      unitId: 0,
      active: false,
      canUserViewArchivedCalleds: false,
      canCreateCalledInMobile: false,
      canInteractWithCalledWhereAreNotRequesterOrAssigned: false,
      phone: "",
      employeehourvalue: 0,
      valueforcalled: 0,
      kmvaluerotated: 0,
      UserRoles: [],
      LocationId: [],
      WorkHourId: '',
      WorkHourText: "",
      OrganizationDepartamentId: 0,
      perhour: false,
      calledunit: false,
      isUnityObrigatory: userSession.user.isunityobrigatory,
      isCostControlObrigatory: userSession.user.iscostcontrolobrigatory,
      defaultorganization: "",
    };
    this.state = {
      itemError: {
        document: '',
      },
      startPageItems: this.startPageItems,
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      GroupList: [],
      RoleList: [],
      WorkHoursList: [],
      DepartamentList: [],
      departamentName: "",
      file: null,
      genderList: this.genderList,
      OrgListForDelete: "",
      OrgListForSelect: [],
      OrgList: [],
      OrgListSelect: [],
      OrgListIds: "",

      LocationListForDelete: "",
      LocationListForSelect: [],
      LocationList: [],
      LocationListSelect: [],
      statesList: [],
      unitList: [],
      openOrgInsertModal: false,
      openLocationInsertModal: false,

      openDialogNewAgents: false,
      valueNewAgents: 0.00,
      newAgentsAccept: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.importModelFile = this.importModelFile.bind(this);
    this.emailExists = this.emailExists.bind(this);
    this.closeLocationModal = this.closeLocationModal.bind(this)
    this.closeOrgModal = this.closeOrgModal.bind(this)
    this.getUnitList = this.getUnitList.bind(this)
  }

  closeLocationModal = () => this.setState({ openLocationInsertModal: false })

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {

    this.getUnitList();
    this.getOrganizations();
    this.getRoles();
    this.getWorkHours();
    this.getAdresses();
    this.getStates();
  }

  async getAllGrous() {
    this.setState({ loading: true });
    var groups = await Service.getGroups();
    this.setState({ loading: false, GroupList: groups });
  }

  async getAdressesById() {

    let id = 4001
    Api.get(`/addresses/organizations/${id}`).then(result => {
      if (result.data.success) {

        const intl = this.props.intl;
        this.setState({
          loading: false,
        });
      }
      else {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

        });
        Api.kickoff(result);

      }
    })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({ id: "process.error" })
          });
        }
      });
  }

  async getUnitList() {
    let result = await getAllWithoutIncludes()
    if (result && result.success) {
      if (result.data && result.data.length > 0)
        this.setState({ unitList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }
  async getStates() {
    let result = await getStates()
    if (result && result.success) {
      if (result.data && result.data.length > 0)
        this.setState({ statesList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }
  async getUnitList() {
    let result = await getAllWithoutIncludes()
    if (result && result.success) {
      if (result.data && result.data.length > 0)
        this.setState({ unitList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getAdresses() {

    Api.get('/addresses').then(result => {
      if (result.data.success) {

        const intl = this.props.intl;

        this.setState({
          loading: false,
        });

      }
      else {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

        });
        Api.kickoff(result);

      }
    })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({ id: "process.error" })
          });
        }
      });


  }

  async getAllOrganizations(ids) {
    if (ids.length > 0) {
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.id);
      //this.setState({ loading: true });

      var orgs = await Service.getOrganizationsByGroup(urlids);


      orgs = orgs.map((org) => {

        return {
          ...org,
          name: `( ${org.group.name} ) ${org.name}`,
        };
      });

      this.setState({ loading: false, OrganizationList: orgs });
      //this.setState({ loading: false, GroupList: groups });
    }
  }

  async GetLocationsByOrganizationId(ids) {
    if (ids.length > 0) {
      let urlids = ids.map((item) => item.id);

      var locations = await Service.getLocationsByOrganizations(urlids);
      if (locations != null && Array.isArray(locations)) {
        locations = locations.map((location) => {
          return {
            ...location,
            name: `( ${location.organization.name} ) ${location.name}`,
          }
        });
        this.setState({ loading: false, LocationListForSelect: locations });
      }
      else {
        this.setState({ loading: false });
      }

    }
  }

  async getAllLocations(ids) {

    if (ids.length > 0) {
      this.setState({ loading: true });
      let id = ids.map((item) => item.id);

      let locations = []


      Api.get(`/addresses/`).then(result => {

        if (result.data.success) {
          result.data.data.forEach(element => {
            id.forEach(idteste => {

              if (element.organization.id === idteste) {
                locations = [...locations, element]

              }
            })
          });

          locations = locations.map((location) => {

            return {
              ...location,
              name: `( ${location.organization.name} ) ${location.name}`,
            };
          });
          this.setState({ loading: false, LocationList: locations });



        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
          Api.kickoff(result);

        }
      })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }

        });

    }

  }

  async getAllDepartaments(ids) {
    if (ids.length > 0) {
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.id);
      //this.setState({ loading: true });

      var depts = await Service.getDepartaments(urlids);

      if (depts != null && Array.isArray(depts)) {

        depts = depts.map((dept) => {
          return {
            ...dept,
            parentname: dept.name,
            order: dept.organization.name,
          };
        });

        this.setState({ loading: false, DepartamentList: depts });
      }
      else {
        this.setState({ loading: false });
      }
    }
  }

  async getWorkHours() {
    this.setState({ loading: true });

    var result = await Service.getWorkHoursList();

    if (result.success) {
      this.setState({ loading: false, WorkHoursList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  onFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();

    //data.append(key, this.state.item[key]);
    if (this.state.file != null) {
      data.append("file", this.state.file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      Api.post("/users/planilhas", data, config)

        .then((result) => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              resultList: [],
            });
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                result.data && result.data.errors
                  ? result.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
            Api.kickoff(result);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }) + err.response,
            });
          }
        });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "excel" }),
      });
    }
  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  importModelFile() {
    this.setState({ loading: true });
    var url = "/users/importfile";

    Api.get(url, {
      responseType: "blob",
    }).then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));

      saveAs(url, "modelo.xlsx");
      this.setState({ loading: false });
    });
  }

  async getDepartament(orgId) {
    this.setState({ loading: true });
    var result = await Service.getDepartamentList(orgId);
    if (result.success) {
      this.setState({ loading: false, DepartamentList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await getOrg();
    if (result.success) {
      this.setState({ loading: false, OrgListForSelect: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getRoles() {
    this.setState({ loading: true });
    var result = await Service.getRoles();
    if (result.success) {

      let idToRemove = 6;

      const rolesList = result.data.filter(item => item.id !== idToRemove);

      this.setState({ loading: false, RoleList: rolesList });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  handleChangePhone(e) {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['phone']: e
      }
    }));
  }

  emailExists = e => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    const name = e.target.name;
    const value = e.target.value;

    if (name === "email") {
      if (value.length > 0) {
        let data = new FormData();
        data.append("email", value)

        Api.post("/users/emailexists", data)

          .then((result) => {
            if (result.data.success) {
              this.setState({ loading: false });
              if (result.data.data && result.data.data.id && result.data.data.id > 0) {
                let errorMessage = intl.formatMessage({ id: "email.already.registered" })

                if (result.data.data.userroles && result.data.data.userroles.length > 0) {
                  if (result.data.data.userroles.length > 1) {
                    errorMessage += intl.formatMessage({ id: "profile.text.header" })
                    result.data.data.userroles.map((item, idx) => {
                      if (idx == 0)
                        errorMessage += item.role.displayname
                      else
                        errorMessage += ` ,${item.role.displayname}`
                    })
                  } else {
                    errorMessage += intl.formatMessage({ id: "profile.text" }) + ` ${result.data.data.userroles[0].role.displayname}`
                  }
                }

                this.setState({
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: errorMessage
                });
              }
            } else {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  result.data && result.data.errors
                    ? result.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }),
              });
              Api.kickoff(result);
            }
          })
          .catch((err) => {
            if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {
              if (!isNaN(err.response.data.errors[0])) {
                this.setState({
                  loading: false,
                  openDialogNewAgents: true,
                  valueNewAgents: err.response.data.errors[0],
                });
              } else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage:
                    err.response && err.response.data && err.response.data.errors
                      ? err.response.data.errors[0]
                      : intl.formatMessage({ id: "process.error" }),
                });
              }
            } else if (err.response && err.response.data && err.response.data.errors) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }),
              });
            } else {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }) + err.response,
              });
            }
          });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;


    if (e.target.name === "valueforcalled" || e.target.name === "employeehourvalue" || e.target.name === "kmvaluerotated") {
      if (e.target.value < 0) {
        value = 0
      }
    }



    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));



    if (e.target.name === "phone") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["phone"]: this.mtel(value),
        },
      }));
    }

    if (name === 'document') {

      var valor = 0;

      if (value.length <= 14) {
        valor = this.cpfMask(value)
      }
      else {
        valor = this.CNPJMask(value)
      }
      valor.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } })) : ((valor.length !== 14 && valor.length !== 18) || !this.isCPFOrCNPJValid(value)) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF/CNPJ inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: valor
        }
      }))
    }

    if (name === 'email') {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value.toLowerCase(),
        },
      }));
      
    }
  }

  cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
  CNPJMask = (valor) => {
    return valor
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  isCPFOrCNPJValid(value) {
    // Remove caracteres não numéricos
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length === 11) {
      // Validação CPF
      let sum = 0;
      let remainder;

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(numericValue.substring(i - 1, i)) * (11 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(numericValue.substring(9, 10))) {
        return false;
      }

      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(numericValue.substring(i - 1, i)) * (12 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      return remainder === parseInt(numericValue.substring(10, 11));
    } else if (numericValue.length === 14) {
      // Validação CNPJ
      let tamanho = numericValue.length - 2;
      let numeros = numericValue.substring(0, tamanho);
      const digitos = numericValue.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
      }

      tamanho = tamanho + 1;
      numeros = numericValue.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      return resultado === parseInt(digitos.charAt(1));
    }

    return false;
  }


  validateCpf = value => {
    let sum, rest;

    sum = 0

    if (value == "00000000000" || value == "000.000.000-00") return false;

    value = value.replaceAll(".", "").replaceAll("-", "")

    for (let i = 1; i <= 9; i++) sum += parseInt(value.substring(i - 1, i)) * (11 - i)
    rest = (sum * 10) % 11

    if ((rest == 10) || (rest == 11)) rest = 0
    if (rest != parseInt(value.substring(9, 10))) return false

    sum = 0
    for (let i = 1; i <= 10; i++) sum += parseInt(value.substring(i - 1, i)) * (12 - i)
    rest = (sum * 10) % 11

    if ((rest == 10) || (rest == 11)) rest = 0
    if (rest != parseInt(value.substring(10, 11))) return false
    return true
  }

  onChangeFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  handleSubmitAccept = async (e) => {
    await this.setState({ newAgentsAccept: true });
    this.handleSubmit(e);
  }

  checkSelectedRoles = (item) => {
    if (this.state.item.UserRoles.includes(item.id))
      return true
  }

  handleSubmit = async (e) => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });

    let data = new FormData();

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
      },
    }));

    if (this.state.item.UserRoles == null || this.state.item.UserRoles == "") {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "selected.group" }),
      });
      return;
    }

    if (this.state.item.document && this.isCPFOrCNPJValid(this.state.item.document) == false) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo CPF/CNPJ não foi validado"
      });
      return;
    }
    if (this.state.item.kmvaluerotated <= 0 && this.state.item.isCostControlObrigatory) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "user.edit.km.value.field.invalid" }),
      });
      return
    }
    let roleItemTech = this.state.RoleList.filter(item => item.roletype == 2);
    if (roleItemTech.length > 0) {
      let roleUserTech = this.state.item.UserRoles.filter(item => item == roleItemTech[0].id)
      if (roleUserTech.length > 0) {
        if (this.state.item.perhour && (this.state.item.employeehourvalue == null || this.state.item.employeehourvalue == "" || this.state.item.employeehourvalue == 0) && this.state.item.isCostControlObrigatory) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "user.edit.hh.value.field.invalid" }),
          });
          return
        } else if (this.state.item.calledunit && (this.state.item.valueforcalled == null || this.state.item.valueforcalled == "" || this.state.item.valueforcalled == 0) && this.state.item.isCostControlObrigatory) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "user.edit.value.per.called.field.invalid" }),
          });
          return;
        } else if (!this.state.item.calledunit && !this.state.item.perhour && this.state.item.isCostControlObrigatory) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "user.edit.cost.parameter.missing" }),
          });
          return;
        }
      }
    }

    if (this.state.item.Organizations == 0) {
      var checkIfIsTechOrGlobalAdm = false
      var checkIfIsOtherRole = false
      var checkRole = 0
      this.state.item.UserRoles.forEach(element => {
        if (element === 1 || element === 5)
          checkIfIsTechOrGlobalAdm = true
      });
      if (checkIfIsTechOrGlobalAdm == false) {
        let roleListToFormat = this.state.RoleList.map(element => {
          if (this.checkSelectedRoles(element))
            return element.displayname
        })
        if (roleListToFormat != null && roleListToFormat.length > 0) {
          let filteredRoles = roleListToFormat.filter(i => typeof i !== "undefined")
          if (filteredRoles && filteredRoles.length > 0) {
            let requiredOrgMessage = intl.formatMessage({ id: "user.client.registration.usergroup.missing.organizations" })
            if (filteredRoles.length > 1) {
              requiredOrgMessage = intl.formatMessage({ id: "user.client.edit.to.usergroup.plural" })
            }
            requiredOrgMessage += `${filteredRoles.join(", ")}` + intl.formatMessage({ id: "user.client.edit.missing.organizations" })

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: requiredOrgMessage,
            });
          } else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({ id: "user.client.edit.usergroup.missing.organizations" }),
            });
          }
        }
        return
      }
    }

    if (this.state.item.startPage == null || (this.state.item.startPage && this.state.item.startPage.length === 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "user.client.edit.invalid.initial.screen.field" }),
      });
      return
    } else {
      let setStartPage = new Set()
      this.state.startPageItems.map(item => setStartPage.add(item.id))
      let setStartPageStatus = setStartPage.has(this.state.item.startPage)
      if (!setStartPageStatus) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({ id: "user.client.edit.invalid.initial.screen.field" }),
        });
        return
      }
    }

    let idsStringOrg = "";
    if (this.state.OrgList.length > 0) {
      this.state.OrgList.forEach(
        (organization) => (idsStringOrg += organization.id + ",")
      );
    }

    var newStr = idsStringOrg.slice(0, -1);
    data.append("Organizations", newStr);

    let idsStringLocation = "";
    if (this.state.LocationList.length > 0) {
      this.state.LocationList.forEach(
        (organization) => (idsStringLocation += organization.id + ",")
      );
    }

    newStr = idsStringLocation.slice(0, -1);
    data.append("LocationId", newStr);

    if (this.state.newAgentsAccept) {
      data.append("acceptNewAgents", true);
      this.setState({ newAgentsAccept: false });
    }

    if (this.state.item.perhour) {
      if (this.state.item.employeehourvalue.length > 0) {
        data.append("costparameter", 1)
        data.append("valueforcalled", 0)
        data.append("employeehourvalue", this.state.item.employeehourvalue)
      } else {
        data.append("employeehourvalue", 0)
        data.append("valueforcalled", 0)
      }
    } else if (this.state.item.calledunit) {
      if (this.state.item.valueforcalled.length > 0) {
        data.append("costparameter", 2)
        data.append("valueforcalled", this.state.item.valueforcalled)
        data.append("employeehourvalue", 0)
      } else {
        data.append("employeehourvalue", 0)
        data.append("valueforcalled", 0)
      }
    } else {
      data.append("employeehourvalue", 0)
      data.append("valueforcalled", 0)

    }

    data.append("kmvaluerotated", this.state.item.kmvaluerotated)

    for (let key in this.state.item) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("userroles") > -1
      ) {

        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("locationid") > -1
      ) {
        data.append(key, this.state.item[key]);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("phone") > -1
      ) {
        data.append(key, this.state.item[key].replace(/\D/gim, ""));
      } else if (key
        .toString()
        .toLowerCase()
        .indexOf("Organizations") > -1 || key
          .toString()
          .toLowerCase()
          .indexOf("LocationId") > -1) {

      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("document") > -1) {
        data.append(key, typeof this.state.item[key] !== 'undefined' || this.state.user[key] !== null ? this.state.item[key] : "");
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("startpage") > -1) {
        if (this.state.item[key] && this.state.item[key].id)
          data.append(key, this.state.item[key].id);
        else
          data.append(key, this.state.item[key]);
      } else if (key.toString().toLowerCase().indexOf("unitid") > -1) {
        if (this.state.item[key] && this.state.item[key].id && this.state.item[key].id > 0)
          data.append(key, this.state.item[key].id);
        else if (this.state.item[key] && this.state.item[key] > 0)
          data.append(key, this.state.item[key]);
        else
          data.append(key, 0);
      } else if (key.toString().toLowerCase().indexOf("workhourid") > -1) {
        if (this.state.item[key] && this.state.item[key].id && this.state.item[key].id > 0)
          data.append(key, this.state.item[key].id);
        else if (this.state.item[key] && this.state.item[key] > 0)
          data.append(key, this.state.item[key]);
        else
          data.append(key, 0);
      } else if (key.toString().toLowerCase().indexOf("gender") > -1) {
        if (this.state.item[key] && this.state.item[key] > 0)
          data.append(key, this.state.item[key]);
        else
          data.append(key, 0);
      } else {
        data.append(key, this.state.item[key]);
      }

    }

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        dataType: "json",
      },
    };

    Api.post("/users", data, config)

      .then((result) => {
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            item: this.initialState,
            files: [],
            openDialogNewAgents: false,
            OrgList: [],
            LocationList: []
          });
          this.getOrganizations();
          this.getRoles();
          this.getWorkHours();

        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {


        if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {
          if (!isNaN(err.response.data.errors[0])) {
            this.setState({
              loading: false,
              openDialogNewAgents: true,
              valueNewAgents: err.response.data.errors[0],
            });

          } else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          }

        } else if (err.response && err.response.data && err.response.data.errors) {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
            err && err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }) + err.response,
          });
        }
      });


  };

  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = "") {
    this.setState({ loading: true });
    PapiroConsole.log(stateName)


    if (stateName == "perhour") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ['calledunit']: false,
        },
      }));
    }

    if (stateName == "calledunit") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ['perhour']: false,
        },
      }));
    }

    if (stateName == 'selectLocations') {
      if (value == true) {
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            ["LocationText"]: text,
            Location: this.state.LocationListForSelect,
            LocationId: this.state.LocationListForSelect,
            OrganizationDepartamentId: 0,
          },
          departamentName: "",
        }));
      } else {
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            ["LocationText"]: text,
            Location: [],
            LocationId: [],
          }
        }));
      }
    } else if (stateName == 'selectOrganizations') {
      PapiroConsole.log(value)
      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            Organizations: this.state.OrgListForSelect,
            OrganizationId: this.state.OrgListForSelect,
            ["OrganizationText"]: text,
            OrganizationDepartamentId: 0,
            LocationId: [],
            locationReset: [],
            Locations: [],
            Location: [],
            DepartamentList: []
          },
          DepartamentList: [],
          LocationList: []
        }));
        await this.GetLocationsByOrganizationId(this.state.OrgListForSelect)
        await this.getAllDepartaments(this.state.OrgListForSelect);
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            Organizations: [],
            OrganizationId: [],
            ["OrganizationText"]: text,
            OrganizationDepartamentId: 0,
            LocationId: [],
            locationReset: [],
            Locations: [],
            Location: [],
            DepartamentList: []
          },
          DepartamentList: [],
          LocationList: []
        }));
        await this.GetLocationsByOrganizationId([])
        await this.getAllDepartaments([]);
      }
    }

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));

    if (stateName === "OrganizationId") {

      //await this.getAllLocations(value);
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["OrganizationText"]: text,
          Organizations: value,
          Location: [],
          OrganizationDepartamentId: 0,
          LocationId: [],
          locationReset: [],
        },
        departamentName: "",
      }));

      this.setState({ DepartamentList: [], LocationList: [] });
      await this.GetLocationsByOrganizationId(value)
      await this.getAllDepartaments(value);

      //this.getDepartament(value);
    }

    if (stateName === "GroupId") {

      //console.log(this.state.item.Organizations, this.state.item.OrganizationId, this.state.item.OrganizationDepartamentId)
      this.setState({ DepartamentList: [] });
      this.setState({ OrganizationList: [] });
      this.setState({ LocationList: [] })

      await this.getAllOrganizations(value);
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["GroupText"]: text,
          Groups: value,
          Organizations: [],
          OrganizationId: [],
          Location: [],
          LocationId: [],
          OrganizationDepartamentId: 0,
          locationReset: [],
        },
        departamentName: "",
      }));
    }

    if (stateName === "Location") {
      let idAddresses = []

      value.forEach(element => {
        idAddresses = [...idAddresses, element.id]
      });

      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["LocationText"]: text,
          Location: value,
          LocationId: idAddresses,
          OrganizationDepartamentId: 0,
        },
        departamentName: "",
      }));
    }

    this.setState({ loading: false });
  }
  getRolesIds() {
    const idList = this.state.RoleList.map((item) => {
      return item.id;
    });
    return idList;
  }

  getRolesNames() {
    const idList = this.state.RoleList.map((item) => {
      return item.name;
    });
    return idList;
  }
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  getWorkHoursByOrgIds = async (ids) => {
    this.setState({ loading: true });

    let data = new FormData();
    data.append('organizationids', ids)

    var result = await Service.getWorkHoursListByOrgs(data);
    if (result.success) {
      this.setState({ loading: false, WorkHoursList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  orgDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {

      this.setState({ loading: true })

      var orgList = [];
      let organizationIds = "";
      var orgDeleteList = this.state.OrgListForDelete.split(",");

      orgDeleteList.map(organizationDelete => {
        if (organizationDelete != "") {
          let returnok = this.state.OrgList.filter(el => el.id != organizationDelete)
          returnok.map(item => {
            if (orgList.filter(el => el.id != item.id)) {
              orgList.push(item);
              organizationIds = `${item.id},${organizationIds}`
            }
          })
        }
      })

      this.setState({
        loading: false,
        OrgList: orgList,
        OrgListIds: organizationIds,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
      }, () => {
        this.getWorkHoursByOrgIds(organizationIds)
      })
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "organization.material.registration.select.organization" })
      })
    }
  }

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: !this.state.openOrgInsertModal
    })
  }

  saveOrgInsertModal = async () => {

    const intl = this.props.intl;

    let organizationIds = ""
    if (this.state.item.OrganizationId && this.state.item.OrganizationId.length > 0) {
      this.state.item.OrganizationId.map(item => {
        if (item && item.id && item.id > 0)
          organizationIds = `${item.id},${organizationIds}`
      })
    }

    this.setState({
      loading: false,
      OrgList: this.state.item.OrganizationId,
      OrgListIds: organizationIds,
      openNotification: true,
      notificationVariant: 'success',
      notificationMessage: intl.formatMessage({ id: "add.success" }),
      openOrgInsertModal: !this.state.openOrgInsertModal,
    }, () => {
      this.getWorkHoursByOrgIds(organizationIds)
    })
  }

  setOrgDelete = async (org) => {
    this.setState({
      OrgListForDelete: org + "," + this.state.OrgListForDelete
    })
  }

  closeOrgModal = () => this.setState({ openOrgInsertModal: false })

  locationDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.LocationListForDelete && this.state.LocationListForDelete.length > 0) {

      this.setState({ loading: true });

      var locationList = [];
      var locationDeleteList = this.state.LocationListForDelete.split(",");

      locationDeleteList.map(itemDelete => {
        if (itemDelete != "") {
          let returnok = this.state.OrgList.filter(el => el.id != itemDelete)
          returnok.map(item => {
            if (locationList.filter(el => el.id != item.id)) {
              locationList.push(item);
            }
          })
        }
      })

      this.setState({
        loading: false,
        LocationList: locationList,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
      })

    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "select.location" })
      })
    }
  }

  openLocationInsertModal = async () => {
    this.setState({
      openLocationInsertModal: !this.state.openLocationInsertModal
    })
  }

  saveLocationInsertModal = async () => {

    const intl = this.props.intl;

    this.setState({
      loading: true
    })

    this.setState({
      loading: false,
      LocationList: this.state.item.LocationId,
      openNotification: true,
      notificationVariant: 'success',
      notificationMessage: intl.formatMessage({ id: "add.success" }),
      openLocationInsertModal: !this.state.openLocationInsertModal,
    })

  }

  setLocationDelete = async (org) => {
    this.setState({
      LocationListForDelete: org + "," + this.state.LocationListForDelete
    })
  }

  openDialogNewAgents = () => {
    this.setState({
      openDialogNewAgents: !this.state.openDialogNewAgents
    })
  }

  render() {


    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item, itemError } = this.state;
    const intl = this.props.intl;

    PapiroConsole.log("this.state.unitList")
    PapiroConsole.log(this.state.unitList)
    PapiroConsole.log(this.state.item.WorkHourId)

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    let checkIsActive = false
    if (item.UserRoles && item.UserRoles.length > 0) {
      let techFilter = item.UserRoles.filter(item => item === 5);
      if (techFilter.length > 0) {
        checkIsActive = true
      }
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />

            <Dialog
              open={this.state.openDialogNewAgents}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: "dialog.title.warning.max.users.reached" })}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {intl.formatMessage({ id: "warning.max.users.reached.first.half" })} {intl.formatMessage({ id: "currency" })} {this.state.valueNewAgents} {intl.formatMessage({ id: "warning.max.users.reached.second.half" })}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.openDialogNewAgents} variant="contained" color="secondary" autoFocus >
                  {intl.formatMessage({ id: "cancel" })}
                </Button>
                <Button variant="contained" onClick={this.handleSubmitAccept} color="primary" >
                  {intl.formatMessage({ id: "accept" })}
                </Button>
              </DialogActions>
            </Dialog>

            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid xs={12} sm={4}></Grid>
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >

                {/*<Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({ id: "organization" })}
                      options={this.state.OrganizationList}
                      stateName="OrganizationId"
                      changeSelect={this.changeValues}
                      selected={item.OrganizationId}
                    />
                  </Grid>
                </Grid>*/}

                {/*<Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label="Localizações"
                      name="LocationId"
                      options={this.state.LocationList}
                      stateName="Location"
                      changeSelect={this.changeValues}
                      selected={item.locationReset}
                    />
                  </Grid>
              </Grid>*/}

                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{intl.formatMessage({ id: "select.organizations" })}*</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >

                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={intl.formatMessage({ id: "organization.material.registration.remove.organization" })}
                            msg={intl.formatMessage({ id: "user.client.registration.confirm.remove.organization.from.user" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            msgAgree={intl.formatMessage({ id: "yes" })}
                            handleConfirmation={e => this.orgDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openOrgInsertModal()} >
                            {intl.formatMessage({ id: "organization.material.registration.add.organization" })}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.OrgList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setOrgDelete(itm);
                              } else {
                                this.setState({
                                  OrgListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: intl.formatMessage({ id: "menu.organization" }), field: 'name', width: 500 },
                            ]}
                            HeadersVisibility="None"
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>

                <Accordion style={{ marginTop: "6px", marginBottom: "10px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{intl.formatMessage({ id: "user.registration.select.locations" })}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >

                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={intl.formatMessage({ id: "user.client.edit.remove.location" })}
                            msg={intl.formatMessage({ id: "user.client.edit.confirm.remove.user.location" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            msgAgree={intl.formatMessage({ id: "yes" })}
                            handleConfirmation={e => this.locationDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openLocationInsertModal()} >
                            {intl.formatMessage({ id: "user.client.edit.add.locations" })}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.LocationList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setLocationDelete(itm);
                              } else {
                                this.setState({
                                  LocationListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: intl.formatMessage({ id: "locations" }), field: 'name', width: 500 },
                            ]}
                            HeadersVisibility="None"
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>

                <Grid container spacing={2}>
                  { item.Organizations != 0 && <Grid item xs={12} sm={12}>
                        <SimpleAutoComplete
                          label={intl.formatMessage({id:"default.organization"})}
                          name="defaultorganization"
                          options={item.Organizations}
                          stateName="defaultorganization"
                          changeSelect={this.changeValues}
                          selected={item.defaultorganization}
                        />
                      </Grid> }
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      id="grouped-demo"
                      options={this.state.DepartamentList.sort(
                        (a, b) => -b.order.localeCompare(a.order)
                      )}
                      groupBy={(option) => option.order}
                      inputValue={this.state.departamentName}
                      onInputChange={(event, newInputValue) => {
                        this.setState({ departamentName: newInputValue });
                      }}
                      getOptionLabel={(option) => option.parentname}
                      style={{ minWidth: "100%", width: "100%" }}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState((prevState) => ({
                            item: {
                              ...prevState.item,
                              OrganizationDepartamentId: value.id,
                            },
                          }));
                        } else {
                          this.setState((prevState) => ({
                            item: {
                              ...prevState.item,
                              OrganizationDepartamentId: 0,
                            },
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ minWidth: "100%", width: "100%" }}
                          label="Departamentos"
                          variant="outlined"
                        />
                      )}
                    />
                    {/* <SimpleAutoComplete label={intl.formatMessage({id:"departament"})} options={this.state.DepartamentList} stateName='OrganizationDepartamentId' changeSelect={this.changeValues} selected={item.OrganizationDepartamentId} /> */}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "email" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      type="email"
                      variant="outlined"
                      name="email"
                      onChange={this.handleInputChange}
                      onBlur={this.emailExists}
                      value={item.email}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "document" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      type="document"
                      variant="outlined"
                      name="document"
                      onChange={this.handleInputChange}
                      value={item.document}
                      InputLabelProps={{ shrink: true }}
                      error={itemError.document?.length > 0}
                      helperText={itemError.document}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} lg={6} >
                    <SimpleSelect
                      label={`Localidade (estado)`}
                      name="state"
                      options={this.state.statesList}
                      stateName="state"
                      changeSelect={this.changeValues}
                      selected={item.state} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({ id: "gender" })}
                      name="gender"
                      options={this.state.genderList}
                      stateName="gender"
                      changeSelect={this.changeValues}
                      selected={item.gender}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <PhoneInput
                      inputStyle={{
                        padding: '23.5px 14px 23.5px 58px',
                        width: '100%'
                      }}
                      style={{
                        marginTop: 16,
                        marginBottom: 8
                      }}
                      id="phone"
                      name="phone"
                      country={'br'}
                      value={item.phone}
                      onChange={this.handleChangePhone}
                      InputLabel={intl.formatMessage({ id: "phone" })}
                      specialLabel={intl.formatMessage({ id: "phone" })}
                      inputProps={{
                        name: intl.formatMessage({ id: "cellphone" })
                      }} />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      label={intl.formatMessage({ id: "called.distance.value" })}
                      variant="outlined"
                      value={item.kmvaluerotated}
                      onChange={this.handleInputChange}
                      name="kmvaluerotated"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete="fname"
                      required={item.isCostControlObrigatory}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography component='h1' variant='h5'>
                      {intl.formatMessage({ id: "cost.parameter" })}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <SimpleCheckBox icon="true" changeSelect={this.changeValues} stateName='perhour' fullWidth label='Por hora' autoComplete='fname' name="perhour" selected={item.perhour} variant='outlined' />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SimpleCheckBox icon="true" changeSelect={this.changeValues} stateName='calledunit' fullWidth label='Por unidade de chamado' autoComplete='fname' name="calledunit" selected={item.calledunit} variant='outlined' />
                  </Grid>
                  <Grid item xs={12} sm={4}></Grid>

                  {this.state.item.calledunit && (
                    <Grid item xs={3} sm={3}>
                      <TextField
                        label={intl.formatMessage({ id: "value.per.called" })}
                        variant="outlined"
                        value={item.valueforcalled}
                        onChange={this.handleInputChange}
                        name="valueforcalled"
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: this.NumberFormatCustom,
                        }}
                        autoComplete="fname"
                        required={item.isCostControlObrigatory}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>)}

                  {this.state.item.perhour && (
                    <Grid item xs={3} sm={3}>
                      <TextField
                        label={intl.formatMessage({ id: "called.employee.hour.value" })}
                        variant="outlined"
                        value={item.employeehourvalue}
                        onChange={this.handleInputChange}
                        name="employeehourvalue"
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: this.NumberFormatCustom,
                        }}
                        autoComplete="fname"
                        required={item.isCostControlObrigatory}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>)}

                  {(item.UserRoles && ((item.UserRoles.length == 1 && item.UserRoles[0] != 4) || item.UserRoles.length > 1)) &&
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({ id: "menu.workhours" })}
                        options={this.state.WorkHoursList}
                        stateName="WorkHourId"
                        changeSelect={this.changeValues}
                        selected={item.WorkHourId}
                        required
                      />
                    </Grid>}
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({ id: "startpage" })}
                      name="startPage"
                      options={this.state.startPageItems}
                      stateName="startPage"
                      changeSelect={this.changeValues}
                      selected={item.startPage}
                      required
                    />
                  </Grid>
                  <Grid item xs={10} sm={11.5}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "unit" })}
                      name="unit"
                      options={this.state.unitList}
                      stateName="unitId"
                      changeSelect={this.changeValues}
                      selected={item.unitId}
                      required={item.isUnityObrigatory}
                    />
                  </Grid>
                  <Grid item xs={2} sm={0.5} alignSelf="center">
                    <Tooltip title="Utilizado para classificar usuário">
                      <Help />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "can.user.view.archived.calleds" })}
                      name={intl.formatMessage({ id: "can.user.view.archived.calleds" })}
                      stateName="canUserViewArchivedCalleds"
                      changeSelect={this.changeValues}
                      selected={item.canUserViewArchivedCalleds}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "active" })}
                      name="active"
                      stateName="active"
                      changeSelect={this.changeValues}
                      selected={item.active}
                    />
                  </Grid>
                  {checkIsActive === true && (
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: "can.create.calleds.in.2move" })}
                        name={intl.formatMessage({ id: "can.create.calleds.in.2move" })}
                        stateName="canCreateCalledInMobile"
                        changeSelect={this.changeValues}
                        selected={item.canCreateCalledInMobile}
                      />
                    </Grid>)}
                  {(item.UserRoles && item.UserRoles.filter(r => r == 4).length > 0) && (
                    <Grid item xs={10} sm={8}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: "customer.can.join.another.customers.call" })}
                        name={intl.formatMessage({ id: "customer.can.join.another.customers.call" })}
                        stateName="canInteractWithCalledWhereAreNotRequesterOrAssigned"
                        changeSelect={this.changeValues}
                        selected={item.canInteractWithCalledWhereAreNotRequesterOrAssigned}
                      />
                    </Grid>)}
                  {(item.UserRoles && item.UserRoles.filter(r => r == 4).length > 0) && <Grid item xs={2} sm={4} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                    <Tooltip title={intl.formatMessage({ id: "customer.can.join.another.customers.call.info" })}>
                      <Help />
                    </Tooltip>
                  </Grid>}
                  <Grid item xs={12} sm={12}>
                    <Typography component="h5" variant="h5">
                      {<FormattedMessage id="user.profile" />}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <MyCheckList
                      label={intl.formatMessage({ id: "menu.groups" })}
                      name="UserRoles"
                      optionsText={this.getRolesNames()}
                      options={this.getRolesIds()}
                      stateName="UserRoles"
                      changeSelect={this.changeValues}
                      selected={item.UserRoles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />

            <DialogAddMultiple
              title={intl.formatMessage({ id: "organization.material.registration.new.organizations" })}
              selected={this.state.item.OrganizationId}
              options={this.state.OrgListForSelect}
              checked={this.state.selectOrganizations}
              changeValues={this.changeValues}
              open={this.state.openOrgInsertModal}
              close={this.closeOrgModal}
              save={this.saveOrgInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })}
              checkboxStateName='selectOrganizations'
              autoCompleteLabel={intl.formatMessage({ id: "organization" })}
              autoCompleteStateName="OrganizationId"
            />
            
            <DialogAddMultiple
              title={intl.formatMessage({ id: "new.locations" })}
              selected={this.state.item.Location}
              options={this.state.LocationListForSelect}
              checked={this.state.selectLocations}
              changeValues={this.changeValues}
              open={this.state.openLocationInsertModal}
              close={this.closeLocationModal}
              save={this.saveLocationInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "select.all.locations" })}
              checkboxStateName='selectLocations'
              autoCompleteLabel={intl.formatMessage({ id: "locations" })}
              autoCompleteStateName="LocationId"
            />

          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
UserRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(UserRegistration))
);
