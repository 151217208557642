import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Api from '../../../services/api';
import * as calledsActions from '../../../redux/actions/calleds';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import { Styles } from "../../../styles/material-styles";
import { Overlay } from '../../../styles/global';
import CustomizedSnackbars from '../../../components/material-snackbars';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getActiveByHirerIdContext, saveCalledClosingStatus } from '../../../services/calledclosingstatus.service'
import { PapiroConsole } from 'src/utils/papiroConsole';

const CalledClosingStatusStep = props => {

  const intl = props.intl
  const classes = props.classes
  const called = props.called
  let [openNotification, setOpenNotification] = useState(false)
  let [notificationVariant, setNotificationVariant] = useState("error")
  let [notificationMessage, setNotificationMessage] = useState("")
  let [loading, setLoading] = useState(false)
  let [calledClosingStatusList, setCalledClosingStatusList] = useState([])
  let [calledclosingstatusId, setCalledClosingStatusId] = useState(null)
  const [updateRadio, setUpdateRadio] = useState(true)

  const getCalledClosingStatusList = async _ => {
    setLoading(true)
    let result = await getActiveByHirerIdContext()
    if(result && result.data) {
      setCalledClosingStatusList(result.data)
      setLoading(false)
    } else {
      setLoading(false)
      setOpenNotification(true)
      setNotificationVariant("error")
      setNotificationMessage(result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  }

  const sendSaveCalledClosingStatus = async e => {
    // e.preventDefault()
    // const { userSession } = props

    // PapiroConsole.log("calledclosingstatusId aqui")
    // PapiroConsole.log(calledclosingstatusId)

    // setLoading(true)

    // if(calledclosingstatusId == null) {
    //   setLoading(false)
    //   setOpenNotification(true)
    //   setNotificationVariant("error")
    //   setNotificationMessage(intl.formatMessage({id:"calledclosingstep.select.called.situation"}))
    //   return
    // }

    // let data = new FormData();
    // data.append('calledid', called.id)
    // data.append('calledclosingstatusid', calledclosingstatusId.id)
    // data.append('createduser', userSession.user.id)

    // let config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   }
    // };

    // if((props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) || (props.caninformutilizedmaterialsincalledfinish)){
    //   let result = await saveCalledClosingStatus(data)
    //   if(result && result.success) {
    //     setLoading(false)
    //     props.setFinishStep(3)
    //     props.setMessage("success", "Situação salva com sucesso.", true)
    //     if(props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) {
    //       props.setNavValue("check")
    //       props.nextStep()
    //     } else if (props.caninformutilizedmaterialsincalledfinish) {
    //       props.setNavValue("material")
    //       props.nextStep()
    //     } 
    //   } else {
    //     setLoading(false)
    //     setOpenNotification(true)
    //     setNotificationVariant("error")
    //     setNotificationMessage(result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
    //     Api.kickoff(result);
    //   }
    // }else {
    //   props.handleSubmit(data)
    // }

    e.preventDefault()
    const { userSession } = props

    PapiroConsole.log("calledclosingstatusId aqui")
    PapiroConsole.log(calledclosingstatusId)

    setLoading(true)

    if(calledclosingstatusId == null) {
      setLoading(false)
      setOpenNotification(true)
      setNotificationVariant("error")
      setNotificationMessage(intl.formatMessage({id:"calledclosingstep.select.called.situation"}))
      return
    }

    let data = new FormData();
    data.append('calledid', called.id)
    data.append('calledclosingstatusid', calledclosingstatusId.id)
    data.append('createduser', userSession.user.id)

    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    if(userSession.user.email == "lobm@poli.br"  || userSession.user.email == "suporte@mexx.ai"  || userSession.user.email == "horaciooliveirajob@gmail.com"){
      if((props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) || (props.caninformutilizedmaterialsincalledfinish)){
          let result = await saveCalledClosingStatus(data)
          if(result && result.success) {
            setLoading(false)
            props.setFinishStep(3)
            props.setMessage("success", "Situação salva com sucesso.", true)
            if(props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) {
              props.setNavValue("check")
              props.nextStep()
            } else if (props.caninformutilizedmaterialsincalledfinish) {
              props.setNavValue("material")
              props.nextStep()
            } 
          } else {
            setLoading(false)
            setOpenNotification(true)
            setNotificationVariant("error")
            setNotificationMessage(result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
            Api.kickoff(result);
          }
        }else {
          props.handleSubmit(data)
        }
    } 
    else{
      let result = await saveCalledClosingStatus(data)
      if(result && result.success) {
        setLoading(false)
        props.setFinishStep(3)
        props.setMessage("success", "Situação salva com sucesso.", true)
        if(props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) {
          props.setNavValue("check")
          props.nextStep()
        } else if (props.caninformutilizedmaterialsincalledfinish) {
          props.setNavValue("material")
          props.nextStep()
        } else {
          props.handleSubmit()
        }
      } else {
        setLoading(false)
        setOpenNotification(true)
        setNotificationVariant("error")
        setNotificationMessage(result && result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
        Api.kickoff(result);
      }
    }

  


   
  }

  const changeNextStepText = _ => {
    if((props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) || props.caninformutilizedmaterialsincalledfinish) {
      return intl.formatMessage({id:"next"})
    } else {
      return intl.formatMessage({id:"end.called"})
    }
  }

  const previousStep = _ => {
    props.setNavValue("action")
    props.previousStep()
  }

  const closeNotification = _ => {
    setOpenNotification(false)
  }

  useEffect(() => {
    getCalledClosingStatusList()
  }, [])

  return (
    <form name='CalledClosingStatusForm' onSubmit={sendSaveCalledClosingStatus} encType='multipart/form-data' className={classes.form} >
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={12} sm={12}>
          <FormattedMessage id="closingstatus" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SimpleAutoComplete
            label={intl.formatMessage({ id: "closingstatus" })}
            options={calledClosingStatusList}
            stateName='calledclosingstatusId'
            changeSelect={(stateName, value) => {
              setCalledClosingStatusId(value)
            }}
            selected={calledclosingstatusId} />
        </Grid>
        <Grid item container spacing={2} justifyContent='space-between' direction='row'>
          <Grid item xs={6} sm={6} onClick={_ => setUpdateRadio(!updateRadio)}>
              {props.actionRadio()}
          </Grid>
          <Grid item container xs={6} sm={6} justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
            <Grid item xs={6} sm={6}>
              {props.previous || props.SLAPrevious ?
                (
                <Button
                  variant='contained'
                  type="button"
                  onClick={previousStep}
                  fullWidth >
                  {intl.formatMessage({id:"previous"})}
                </Button>
                ) : (
                  <Button
                  variant='contained'
                  type="button"
                  onClick={props.closeModal}
                  fullWidth >
                  {intl.formatMessage({id:"cancel"})}
                </Button>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                type='button'
                variant='contained'
                color='primary'
                onClick={sendSaveCalledClosingStatus}
                fullWidth >
                { changeNextStepText() }
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />

      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}
    </form>
  )
}

CalledClosingStatusStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledClosingStatusStep))));